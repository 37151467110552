import { CommonModule, Location } from '@angular/common';
import {
  Component, HostListener, OnDestroy, OnInit,
} from '@angular/core';
import { Router, RouterModule } from '@angular/router';


import { LanguageService, PageService, Languages } from '@instaclause-webapp/shared-core';
import { ButtonComponent, ButtonColors } from '@instaclause-webapp/shared-ui';
import { TranslocoModule } from '@jsverse/transloco';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-ui-bottom',
  standalone: true,
  imports: [ CommonModule, RouterModule, ButtonComponent, TranslocoModule ],
  templateUrl: './bottom.component.html',
  styleUrl: './bottom.component.scss',
})
export class BottomComponent implements OnInit, OnDestroy {

  avalibleLanguages!: Languages[];
  isHome = false;
  currentRoute!: string;
  isOpenLanguages = false;
  buttonColor = ButtonColors;

  private subscription = new Subscription();

  constructor(
    protected languageService: LanguageService,
    private pageService: PageService,
    private router: Router,
    private location: Location,
  ) { }


  @HostListener('document:click', [ '$event' ])
  onDocumentClick(event: MouseEvent): void {
    const clickedInside = (event.target as HTMLElement).closest('.bottom-language-wrapper');
    if (!clickedInside) {
      this.isOpenLanguages = false;
    }
  }

  ngOnInit(): void {
    this.avalibleLanguages = this.languageService.getAvailableLangs();
    this.currentRoute = this.router.url;

    this.subscription = this.pageService.currentPath$.subscribe(url => {
      this.isHome = url === '/contracts';
      this.currentRoute = url;
    });
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  onChangeLanguage(language: Languages): void {
    this.languageService.changeLanguage(language);
  }

  onBack(): void {
    this.location.back();
  }

  createContract() {
    console.log('create contract');

  }

  onDeleteContract(contractId: string) {
    console.log('delete contract', contractId);
  }

  languagePickerOpen() {
    this.isOpenLanguages = !this.isOpenLanguages;
  }
}
