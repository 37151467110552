
import {
  CdkOverlayOrigin, Overlay, OverlayConfig, OverlayModule, OverlayRef,
} from '@angular/cdk/overlay';
import { TemplatePortal } from '@angular/cdk/portal';
import { CommonModule } from '@angular/common';
import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  TemplateRef,
  ViewChild,
  ViewContainerRef,
} from '@angular/core';

import {
  LanguageService, User, Languages, AuthService,
} from '@instaclause-webapp/shared-core';
import { InitialsPipe, InlineSvgComponent } from '@instaclause-webapp/shared-ui';
import { TranslocoModule } from '@jsverse/transloco';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-user-menu',
  standalone: true,
  imports: [
    CommonModule,
    OverlayModule,
    InlineSvgComponent,
    TranslocoModule,
    InitialsPipe,
  ],
  templateUrl: './user-menu.component.html',
  styleUrl: './user-menu.component.scss',
})
export class UserMenuComponent implements OnInit, OnDestroy {

  @ViewChild('menuTemplate') menuTemplate!: TemplateRef<any>;
  @Input() user!: User;
  @Output() menuClose = new EventEmitter();

  availableLanguages!: Languages[];
  overlayRef!: OverlayRef;
  languagesLength!: number;
  languageMenuOpen = false;

  private subscription = new Subscription();


  constructor(
    protected languageService: LanguageService,
    private authService: AuthService,
    private overlay: Overlay,
    private viewContainerRef: ViewContainerRef,
  ) {}

  ngOnInit(): void {
    this.availableLanguages = this.languageService.getAvailableLangs();
    this.languagesLength = this.availableLanguages.length;
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  openMenu(origin: CdkOverlayOrigin) {
    if (this.overlayRef) {
      this.overlayRef.detach();
    }

    const positionStrategy = this.overlay.position()
      .flexibleConnectedTo(origin.elementRef)
      .withPositions([
        {
          originX: 'center',
          originY: 'bottom',
          overlayX: 'center',
          overlayY: 'top',
          offsetY: 20,
        },
      ]);

    const overlayConfig = new OverlayConfig({
      positionStrategy,
      hasBackdrop: true,
      backdropClass: 'cdk-overlay-transparent-backdrop',
      scrollStrategy: this.overlay.scrollStrategies.reposition(),
    });

    this.overlayRef = this.overlay.create(overlayConfig);
    const portal = new TemplatePortal(this.menuTemplate, this.viewContainerRef);
    this.overlayRef.attach(portal);

    this.subscription = this.overlayRef.backdropClick().subscribe(() => this.closeMenu());
  }

  closeMenu() {
    if (this.overlayRef) {
      this.menuClose.emit();
      this.overlayRef.detach();
    }
  }

  onChangeLanguageOpen() {
    this.languageMenuOpen = !this.languageMenuOpen;
  }

  onChangeLanguage(language: Languages): void {
    this.languageService.changeLanguage(language);
  }

  async logout() {
    await this.authService.logout();
  }
}
