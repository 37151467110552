import { HttpBackend, HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { Translation, TranslocoLoader } from '@jsverse/transloco';

@Injectable({ providedIn: 'root' })
export class TranslocoHttpLoader implements TranslocoLoader {
  private http: HttpClient;

  constructor(private httpBackend: HttpBackend) {
    this.http = new HttpClient(this.httpBackend);
  }

  getTranslation(lang: string) {
    console.log('TranslocoHttpLoader getTranslation', lang);

    return this.http.get<Translation>(`/assets/i18n/${lang}.json`);
  }
}
