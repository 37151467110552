import { CommonModule } from '@angular/common';
import {
  Component, HostBinding, OnDestroy, OnInit, inject,
} from '@angular/core';
import { RouterModule } from '@angular/router';

import { ToastComponent, ToastService } from '@instaclause-webapp/shared-ui';
import { Subscription } from 'rxjs';

import { BottomComponent } from '../../ui/bottom/bottom.component';
import { MenuComponent } from '../../ui/menu/menu.component';

@Component({
  selector: 'app-main-layout',
  standalone: true,
  imports: [ CommonModule, MenuComponent, RouterModule, BottomComponent, ToastComponent ],
  templateUrl: './main-layout.component.html',
  styleUrl: './main-layout.component.scss',
})
export class MainLayoutComponent implements OnInit, OnDestroy {

  @HostBinding('style.--open-toasts-count')
  private openToastsCount = 0;
  private subscription = new Subscription();
  private toastService = inject(ToastService);


  ngOnInit(): void {
    this.subscription = this.toastService.toasts$.subscribe(toasts => {
      this.openToastsCount = toasts.length;
    });
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }
}
