import { CdkOverlayOrigin, OverlayModule } from '@angular/cdk/overlay';
import { CommonModule } from '@angular/common';
import {
  Component, OnInit, ViewChild, OnDestroy,
} from '@angular/core';
import { RouterModule } from '@angular/router';

import {
  AuthService, LanguageService, PageService, User,
  capitalizeFirstLetter, Languages,
} from '@instaclause-webapp/shared-core';
import { InitialsPipe, LogoComponent, TooltipDirective } from '@instaclause-webapp/shared-ui';
import { ButtonAlertComponent } from '@instaclause-webapp/shared-ui';
import { InlineSvgComponent } from '@instaclause-webapp/shared-ui';
import { TranslocoModule } from '@jsverse/transloco';
import { Subscription } from 'rxjs';

import { UserMenuComponent } from '../user-menu/user-menu.component';

@Component({
  selector: 'app-menu',
  standalone: true,
  imports: [
    CommonModule,
    TranslocoModule,
    LogoComponent,
    InlineSvgComponent,
    ButtonAlertComponent,
    UserMenuComponent,
    OverlayModule,
    RouterModule,
    TooltipDirective,
    InitialsPipe,
  ],
  templateUrl: './menu.component.html',
  styleUrl: './menu.component.scss',
})
export class MenuComponent implements OnInit, OnDestroy {

  @ViewChild(UserMenuComponent) userMenu!: UserMenuComponent;
  user!: User | null;
  avalibleLanguages!: Languages[];
  alertsOpen = false;
  userMenuOpen = false;

  private subscription = new Subscription();


  constructor(
    protected pageService: PageService,
    private authService: AuthService,
    private languageService: LanguageService,
  ) {}

  ngOnInit(): void {
    this.avalibleLanguages = this.languageService.getAvailableLangs();

    this.subscription.add(this.authService.user$.subscribe(user => this.user = user));
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  onChangeLanguage(language: Languages): void {
    this.languageService.changeLanguage(language);
  }

  triggerMenu(event: MouseEvent, trigger: CdkOverlayOrigin): void {
    this.userMenuOpen = !this.userMenuOpen;

    if (this.userMenuOpen) {
      event.stopPropagation();
      this.userMenu.openMenu(trigger);
    } else {
      this.userMenu.closeMenu();
    }
  }

  onCloseMenu() {
    this.userMenuOpen = false;
  }

  capitalizeLetter(value: string): string {
    return capitalizeFirstLetter(value);
  }
}
