import { bootstrapApplication } from '@angular/platform-browser';

import { ENVIRONMENT } from '@instaclause-webapp/shared-core';

import { AppComponent } from './app/app.component';
import { appConfig } from './app/app.config';

bootstrapApplication(AppComponent, appConfig)
  .then((appRef) => {
    const version = appRef.injector.get(ENVIRONMENT).version;
    if (version) {
      console.log(`App version: ${version}`);
    }
  })
  .catch((err) =>
    console.error(err),
  );
