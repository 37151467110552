<div class="menu">
  <div class="menu-container">
    <!-- Breadcrumbs -->
    @if (user) {
      <nav class="breadcrumb">
        <ul class="breadcrumb-list">
          @if (pageService.breadcrumbs$ | async; as breadcrumbs) {
            @for (breadcrumb of breadcrumbs; track $index; let i = $index) {
              @if ($index === 0) {
                <li class="breadcrumb-list-item">
                  <a [routerLink]="['./', breadcrumb]" class="breadcrumb-list-item-link" [class.disabled]="breadcrumbs.length === 1" >
                    @if(breadcrumb === 'settings') {
                      <ui-inline-svg [name]="'settings'"></ui-inline-svg>
                    } @else if(breadcrumb === 'customers') {
                      <ui-inline-svg [name]="'users'"></ui-inline-svg>
                    } @else {
                      <ui-inline-svg [name]="'file-text'"></ui-inline-svg>
                    }
                    <span>{{ capitalizeLetter(breadcrumb) }}</span>
                  </a>
                </li>
              } @else {
                <ui-inline-svg class="breadcrumb-list-divisor" [name]="'chevron-right'"></ui-inline-svg>
                <li class="breadcrumb-list-item">
                  <a [routerLink]="[breadcrumb]" class="breadcrumb-list-item-link" routerLinkActive="active">
                    <span>{{ breadcrumb }}</span>
                  </a>
                </li>
              }
            }
          }
        </ul>
      </nav>
    } @else { 
      {{ 'MENU.LOGIN' | transloco }}
    }

    <!-- Logo -->
    <ui-logo></ui-logo>

    @if (user) {
      <div class="actions">
        <div class="navigation">
          <a uiTooltip
            [tooltipText]="'MENU.CONTRACTS' | transloco"
            [tooltipPosition]="'bottom'"
            [routerLink]="['contracts']"
            class="navigation-item"
            routerLinkActive="active"
          >
            <ui-inline-svg class="navigation-item-icon" [name]="'file-text'"></ui-inline-svg>
          </a>

          <a uiTooltip
            [tooltipText]="'MENU.CUSTOMERS' | transloco"
            [tooltipPosition]="'bottom'"
            [routerLink]="['customers']"
            class="navigation-item"
            routerLinkActive="active"
          >
            <ui-inline-svg class="navigation-item-icon" [name]="'users'"></ui-inline-svg>
          </a>

          <a uiTooltip
            [tooltipText]="'MENU.SETTINGS' | transloco"
            [tooltipPosition]="'bottom'"
            [routerLink]="['settings']"
            class="navigation-item"
            routerLinkActive="active"
          >
            <ui-inline-svg class="navigation-item-icon" [name]="'settings'"></ui-inline-svg>
          </a>
        </div>

        <div class="separator"></div>

        <div class="user">
          <ui-button-alert [open]="alertsOpen" (click)="alertsOpen = !alertsOpen"></ui-button-alert>

          <div
            class="user-avatar"
            cdkOverlayOrigin
            #trigger="cdkOverlayOrigin"
            [ngClass]="{ open: userMenuOpen }"
            (click)="triggerMenu($event, trigger)"
          >
            @if (user.image) {
              <img class="user-avatar-img" [src]="user.image" />
            } @else {
              <span class="user-avatar-initials">{{ (user.displayName || '') | initials }}</span>
            }
          </div>
        </div>

        <app-user-menu class="user-menu" [user]="user" (menuClose)="onCloseMenu()" #userMenu></app-user-menu>
      </div>
    } @else {
      <ul class="languages">
        <li *ngFor="let lang of avalibleLanguages">
          <a class="languages-item" (click)="onChangeLanguage(lang)">
            {{ lang }}
          </a>
        </li>
      </ul>
    }
  </div>
</div>
