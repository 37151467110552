import { Route } from '@angular/router';

import { authGuard } from '@instaclause-webapp/shared-core';

import { LoginComponent } from './layout/login/login.component';
import { MainLayoutComponent } from './layout/main/main-layout.component';


export const appRoutes: Route[] = [
  {
    path: '',
    redirectTo: 'contracts',
    pathMatch: 'full',
  },
  {
    path: '',
    component: MainLayoutComponent,
    children: [
      {
        path: 'contracts',
        loadChildren: () => import('./feature/home/home.routes'),
        canActivate: [ authGuard ],
      },
      {
        path: 'help',
        loadChildren: () => import('./feature/help/help.routes'),
        canActivate: [ authGuard ],
      },
      {
        path: 'settings',
        loadChildren: () => import('./feature/settings/settings.routes'),
        canActivate: [ authGuard ],
      },
    ],
  },
  {
    path: 'login',
    component: LoginComponent,
  },
];
