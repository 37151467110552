<div class="bottom">
  <div class="bottom-container">
    <div class="bottom-utils">
      <div class="bottom-language-wrapper" [class.isOpen]="isOpenLanguages">
        @if (languageService.language$ | async; as currentLanguage) {
          <ui-button
            class="bottom-language-button"
            [color]="buttonColor.GhostOnLight"
            icon="language"
            (click)="languagePickerOpen()"
          >
            {{ 'LANGUAGES.' + currentLanguage.toUpperCase() | transloco }}
          </ui-button>
          <ul class="bottom-language-list">
            @for (language of avalibleLanguages; track language) {
              <li class="bottom-language-list-item" (click)="onChangeLanguage(language)">
                {{ 'LANGUAGES.' + language.toUpperCase() | transloco }}
              </li>
            }
          </ul>
        }
      </div>
      <a class="bottom-link" [routerLink]="['./help']">
        <ui-button [color]="buttonColor.GhostOnLight" icon="help">
          {{ 'BOTTOM.HELP' | transloco }}
        </ui-button>
      </a>
    </div>
    <div class="bottom-actions">
      @if (isHome) {
        <ui-button [color]="buttonColor.GhostOnLight" icon="delete">{{ 'BOTTOM.TRASH' | transloco }}</ui-button>
        <a class="bottom-link" [routerLink]="['./contracts', 'new']">
          <ui-button [color]="buttonColor.GhostOnLight" icon="more">{{ 'BOTTOM.NEW_CONTRACT' | transloco }}</ui-button>
        </a>
      } @else {
        <ui-button [color]="buttonColor.GhostOnLight" icon="arrow-back" (click)="onBack()">{{ 'BOTTOM.BACK' | transloco }}</ui-button>
      }
    </div>
  </div>
</div>
